
export const AccountTypes = () => {
  return {
    SET_TOKEN_BALANCES: 'SET_TOKEN_BALANCES',
    SET_REFERRAL_BUY_INFO: 'SET_REFERRAL_BUY_INFO',
    SET_REFERRAL_PROFIT_INFO: 'SET_REFERRAL_PROFIT_INFO',
    SET_NFT_INFO: 'SET_NFT_INFO',
    SET_REFERRALS_BY_LEVEL: 'SET_REFERRALS_BY_LEVEL',
    SET_UPLINER: 'SET_UPLINER',
    RESET_USER_INFO: 'RESET_USER_INFO',
    SET_GREMLIN_CURRENCY: 'SET_GREMLIN_CURRENCY',
    SET_BNB_CURRENCY: 'SET_BNB_CURRENCY',
    SET_BOOST_QUANTITY: 'SET_BOOST_QUANTITY',
    SET_TOKEN_AVAILABLE_NFT_REWARD: 'SET_TOKEN_AVAILABLE_NFT_REWARD',
    SET_TOTAL_EARNED_REWARD: 'SET_TOTAL_EARNED_REWARD',
    SET_BUY_REFERRALS_BY_LEVEL: 'SET_BUY_REFERRALS_BY_LEVEL',
    SET_EARN_REFERRALS_BY_LEVEL: 'SET_EARN_REFERRALS_BY_LEVEL',
    SET_AVAILABLE_REFERRAL_REWARDS: 'SET_AVAILABLE_REFERRAL_REWARDS',
    SET_H2O_COOLDOWN: 'SET_H2O_COOLDOWN',
    SET_NFT_IDS: 'SET_NFT_IDS',
  }
}

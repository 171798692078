import { AirdropCarousel } from "./airdropCarousel/airdropCarousel"
import { AirdropStatus } from "./airdropStatus/airdropStatus"

export const AirDrop = () => {

  return (
    <div className='mt-32'>
      <AirdropCarousel />
      <AirdropStatus />
    </div>
  )
}

import { ApplicationTypes } from "./types"

const initialState = {
  walletAddress: null,
  web3: null,
  walletRPC: null,
  isNeedUpdate: false,
  defaultReferrer: null,
  notCorrectChain: false,
  selectedNftType: 0,
  availableGremlinsToBuy: 0,
  gremlinBuyInput: '0',
  bothTokensNftPrice: [0, 0, 0, 0, 0, 0],
  dailyNftReward: [],
  isNeedToSetUpliner: false,
  bnbCurrency: 0,
  boostPrice: 0,
  isDisconnect: false,
  transactionStatus: {
    ongoing: false,
    isError: false,
    isSuccess: false,
    content: null
  },
}

export default function ApplicationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ApplicationTypes().SET_WALLET_ADDRESS:
      return { ...state, walletAddress: action.payload }
    case ApplicationTypes().SET_WEB3_INSTANCE:
      return { ...state, walletRPC: action.payload }
    case ApplicationTypes().SET_NEED_UPDATE:
      return { ...state, isNeedUpdate: action.payload }
    case ApplicationTypes().SET_DEFAULT_REFERRER:
      return { ...state, defaultReferrer: action.payload }
    case ApplicationTypes().SET_WALLET_RPC:
      return { ...state, walletRPC: action.payload }
    case ApplicationTypes().SET_NOT_CORRECT_CHAIN:
      return { ...state, notCorrectChain: action.payload }
    case ApplicationTypes().SET_SELECTED_NFT_TYPE:
      return { ...state, selectedNftType: action.payload }
    case ApplicationTypes().SET_AVAILABLE_GREMLINS_TO_BUY:
      return { ...state, availableGremlinsToBuy: action.payload }
    case ApplicationTypes().SET_BOTH_TOKENS_NFT_PRICE:
      return { ...state, bothTokensNftPrice: action.payload }
    case ApplicationTypes().SET_IS_NEED_TO_SET_UPLINER:
      return { ...state, isNeedToSetUpliner: action.payload }
    case ApplicationTypes().SET_GREMLIN_BUY_INPUT:
      return { ...state, gremlinBuyInput: action.payload }
    case ApplicationTypes().SET_BNB_CURRENCY:
      return { ...state, bnbCurrency: action.payload }
    case ApplicationTypes().SET_DAILY_NFT_REWARD:
      return { ...state, dailyNftReward: action.payload }
    case ApplicationTypes().SET_TRANSACTION_STATUS:
      return { ...state, transactionStatus: action.payload }
    case ApplicationTypes().SET_IS_DISCONNECT:
      return { ...state, isDisconnect: action.payload }
    case ApplicationTypes().SET_BOOST_PRICE:
      return { ...state, boostPrice: action.payload }
    default:
      return state
  }
}

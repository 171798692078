import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { Header, HeaderMenu } from 'components/header/header'; import { Footer } from 'components/footer/footer';
import { ApplicationRoutes } from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { initWagmi } from 'utils/initWagmi';
import { config } from 'config';
import { ApplicationActionCreator } from 'store/reducers/applicationReducer/action-creator';
import { AccountActionCreator } from 'store/reducers/accountReducer/action-creator';
import { useAccount, useWalletClient, WagmiConfig } from 'wagmi';
import { Web3Modal } from '@web3modal/react';
import { SetUplinerModal } from 'modals/setUplinerModal';
/* global BigInt */
function App() {
  const dispatch = useDispatch()
  const {
    walletAddress, isNeedUpdate, notCorrectChain, isNeedToSetUpliner
  } = useSelector(state => state.ApplicationReducer)
  const [seconds, setSeconds] = useState(0)
  const [wagmiConfig, setWagmiConfig] = useState()
  const [ethereumClient, setEthereumClient] = useState()
  const [projectId, setProjectId] = useState()
  const [openedMenu, isOpenedMenu] = useState(false)

  useMemo(() => {
    const { wagmiConfig, ethereumClient, projectId } = initWagmi()

    setWagmiConfig(wagmiConfig)
    setEthereumClient(ethereumClient)
    setProjectId(projectId)
  }, [])

  useEffect(() => {
    let interval
    interval = setInterval(() => {
      if (!seconds) {
        if (notCorrectChain) return
        if (walletAddress) {
        }
        setSeconds(1);
      } else if (seconds >= config().HEARTBEAT_RATE) {
        setSeconds(0)
      } else setSeconds(seconds + 1)
    }, 1000)

    return () => clearInterval(interval);
  }, [seconds])

  useEffect(() => {
    if (isNeedUpdate) {
      if (!!walletAddress) {
      }
      dispatch(ApplicationActionCreator.setNeedUpdate(false))
    }
  }, [isNeedUpdate])

  useEffect(() => {
    if (notCorrectChain) alert('Connect to another supported chain')
    if (!!walletAddress && !notCorrectChain) {
      setSeconds(0)
    }
  }, [walletAddress, notCorrectChain])

  useEffect(() => {
    // Function to handle wallet change event
    const handleAccountsChanged = (accounts) => {
      dispatch(ApplicationActionCreator.setWalletAddress(accounts[0]))
      console.log('Active wallet changed:', accounts);
    };

    const handleChainChanged = (chainId) => {
      const newChainId = Number(chainId)

      if (chainId !== config().CHAIN_ID) {
        dispatch(ApplicationActionCreator.setNotCorrectChain(false))
      } else dispatch(ApplicationActionCreator.setNotCorrectChain(true))
    }

    // Check if MetaMask is available
    if (typeof window.ethereum !== 'undefined') {
      // Subscribe to wallet change events
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    // Clean up the subscription on component unmount
    return () => {
      if (typeof window.ethereum !== 'undefined') {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <WagmiConfig config={wagmiConfig}>
        <Header openedMenu={openedMenu} setIsOpenedMenu={isOpenedMenu} />
        <ApplicationRoutes projectId={projectId} ethereumClient={ethereumClient} />
        <Footer />
        {openedMenu && <HeaderMenu setIsOpenedMenu={isOpenedMenu} />}
      </WagmiConfig >
    </BrowserRouter>
  );
}

export default App;

export const defineError = (error) => {

  switch (error.code) {
    case 4001:

      return <p>Request was rejected by user</p>
    case -32603:
      return <p>{error.data.message.replace('"execution reverted:', '')}</p>
    default:
      return <p>Unknown error</p>
  }
}

import { useSelector } from "react-redux"

export const ConnectWallet = (props) => {

  return (
    <button onClick={props.onClick} className={`${props.walletAddress ? 'wallet__connected' : 'bg-purple'} font-interSemibold   whitespace-nowrap px-5 py-3 rounded-lg text-sm font-medium text-white`}>
      {props.text()}
    </button>
  )
}

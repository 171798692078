import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationActionCreator } from "store/reducers/applicationReducer/action-creator"

export const Button = (props) => {
  const { transactionStatus } = useSelector(state => state.ApplicationReducer)
  const { tokenBalances } = useSelector(state => state.AccountReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    let transactionTimeout

    if (transactionStatus.isError || transactionStatus.isSuccess) {
      transactionTimeout = setTimeout(() => {
        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: false,
          isError: false,
          isSuccess: false,
          type: null,
          content: null
        }))
      }, 5000)
    }


    return () => clearTimeout(transactionTimeout)
  }, [transactionStatus])

  const disableDefaultTrigger = props.disabled || transactionStatus.ongoing || !tokenBalances.bnbBalance
  const disableUplinerTrigger = transactionStatus.ongoing

  return (
    <button
      disabled={props.setUpliner ? disableUplinerTrigger : disableDefaultTrigger}
      onClick={props.onClick}
      className={` ${props.className} 
        ${transactionStatus.type === props.type
          ? transactionStatus.isError
            ? 'bg-red-700 shadow-red-700/100'
            : transactionStatus.isSuccess
              ? 'bg-bgPurple shadow-green/50'
              : transactionStatus.ongoing
                ? 'bg-bgPurple shadow-purple/100'
                : 'bg-button shadow-purple/100'
          : 'bg-button shadow-purple/100'
        } 
        font-interMedium w-full transition shadow-custom disabled:bg-bgPurple
        whitespace-nowrap px-6 py-2 rounded-lg text-xl font-medium text-white
        overflow-hidden text-ellipsis button__wrapper
        `}>
      {
        transactionStatus.type === props.type
          ? transactionStatus?.ongoing
            ? transactionStatus.content
            : props.children
          : props.children
      }
    </button>
  )
}

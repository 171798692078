import { Button } from "components/button/button"

export const AirdropStatus = () => {

  return (
    <div>
      <h3 className='text-xl text-white font-jetMedium uppercase text-center  mb-3'>status</h3>
      <div className='text-center justify-center bg-purple py-8 rounded-2xl mb-8 px-4'>
        <p className='flex flex-col sm:flex-row gap-y-2 font-jetMedium text-xl leading-5 text-white'>Your H2O allocation: <span>0</span></p>
      </div>
      <Button>Claim</Button>
    </div>
  )
}

import { AccountTypes } from "./types";
import GremlinToken from 'contracts/gremlinToken.json'
import GremlinNft from 'contracts/gremlinNft.json'
import GremlinContract from 'contracts/gremlinContract'

import { config } from "config";
import { ApplicationActionCreator } from "../applicationReducer/action-creator";
import { initWeb3 } from "utils/initWeb3";
import Web3 from "web3";
import { getBnbCurrency } from "api";
import { getNftAddress } from "utils/getNftAddress";
import { defineError } from "utils/defineError";

export const AccountActionCreator = {
  setUpliner: (upliner) => ({
    type: AccountTypes().SET_UPLINER,
    payload: upliner
  }),
  setTokenBalances: (tokenBalances) => ({
    type: AccountTypes().SET_TOKEN_BALANCES,
    payload: tokenBalances
  }),
  setReferralsByLevel: (referralsByLevel) => ({
    type: AccountTypes().SET_REFERRALS_BY_LEVEL, payload: referralsByLevel
  }),
  setReferralBuyInfo: (referralBuyInfo) => ({
    type: AccountTypes().SET_REFERRAL_BUY_INFO,
    payload: referralBuyInfo
  }),
  setReferralProfitInfo: (referralProfitInfo) => ({
    type: AccountTypes().SET_REFERRAL_PROFIT_INFO,
    payload: referralProfitInfo
  }),
  setNftInfo: (nftInfo) => ({
    type: AccountTypes().SET_NFT_INFO,
    payload: nftInfo
  }),
  resetUserInfo: () => ({
    type: AccountTypes().RESET_USER_INFO
  }),
  setGremlinCurrency: (gremlinCurrency) => ({
    type: AccountTypes().SET_GREMLIN_CURRENCY,
    payload: gremlinCurrency
  }),
  setBoostQuantity: (boostQuantity) => ({
    type: AccountTypes().SET_BOOST_QUANTITY,
    payload: boostQuantity
  }),
  setTokenAvailableNftReward: (tokenAvailableReward) => ({
    type: AccountTypes().SET_TOKEN_AVAILABLE_NFT_REWARD,
    payload: tokenAvailableReward
  }),
  setTotalEarnedReward: (totalEarnedReward) => ({
    type: AccountTypes().SET_TOTAL_EARNED_REWARD,
    payload: totalEarnedReward
  }),
  setEarnReferralsByLevel: (addressEarnReferralsByLevel) => ({
    type: AccountTypes().SET_EARN_REFERRALS_BY_LEVEL,
    payload: addressEarnReferralsByLevel
  }),
  setBuyReferralsByLevel: (addressBuyReferralsByLevel) => ({
    type: AccountTypes().SET_BUY_REFERRALS_BY_LEVEL,
    payload: addressBuyReferralsByLevel
  }),
  setAvailableReferralRewards: referralProfitInfo => ({
    type: AccountTypes().SET_AVAILABLE_REFERRAL_REWARDS,
    payload: referralProfitInfo
  }),
  setH2oCooldown: h2oCooldown => ({
    type: AccountTypes().SET_H2O_COOLDOWN,
    payload: h2oCooldown
  }),
  setNftIds: nftIds => ({
    type: AccountTypes().SET_NFT_IDS,
    payload: nftIds
  }),
  getReferralsBuyLevel:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);
      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);
      const walletAddress = store().ApplicationReducer.walletAddress



      let addressReferrals
      try {
        addressReferrals = await gremlinContract.methods.getReferrals(walletAddress, 7).call({ from: walletAddress })
        addressReferrals = addressReferrals.map(el => {
          const referralCount = +el.toString()
          return referralCount
        })
      } catch (error) {
        console.log(error)
      }
      dispatch(AccountActionCreator.setReferralsByLevel(addressReferrals))
    },
  getNftIds:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);
      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);
      const walletAddress = store().ApplicationReducer.walletAddress

      const nftIdsByLevel = []

      for (let i = 0; i < 6; i++) {
        let nftIds

        const nftContract = new web3.eth.Contract(GremlinNft.abi, getNftAddress(i));
        try {
          nftIds = await nftContract.methods.getAddressNFTs(walletAddress).call({ from: walletAddress })
          nftIds = nftIds.map((el) => {
            return +el.toString()
          })

        } catch (error) {
          console.log(error)
        }

        nftIdsByLevel.push(nftIds)
      }

      console.log(nftIdsByLevel)
      dispatch(AccountActionCreator.setNftIds(nftIdsByLevel))
    },
  getH2oCooldown:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);
      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);
      const walletAddress = store().ApplicationReducer.walletAddress

      let h2oCooldown

      try {
        h2oCooldown = await gremlinContract.methods.addressH2oCooldown(walletAddress).call()
        h2oCooldown = (+h2oCooldown.toString() + config().DAY) * 1000
      } catch (error) {
        console.log(error)
      }

      dispatch(AccountActionCreator.setH2oCooldown(h2oCooldown))

    },
  getAvailableReferralRewards:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);
      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);
      const walletAddress = store().ApplicationReducer.walletAddress

      let availableBuyReward = 0
      let availableEarnReward = 0

      try {
        availableBuyReward = await gremlinContract.methods.getCurrentCoinRewards(walletAddress).call()
        availableBuyReward = availableBuyReward.map(el => {
          return +web3.utils.fromWei(el, 'ether')
        })
      } catch (error) {
        console.log(error)
      }

      try {
        availableEarnReward = await gremlinContract.methods.getCurrentGremlinRewards(walletAddress).call()
        availableEarnReward = availableEarnReward.map(el => {
          return +web3.utils.fromWei(el, 'ether')
        })
      } catch (error) {
        console.log(error)
      }
      console.log('referral reward ', availableBuyReward, availableEarnReward)
      dispatch(AccountActionCreator.setAvailableReferralRewards({
        availableBuyReward, availableEarnReward
      }))

    },
  getBoostQuantity:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);
      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);
      const walletAddress = store().ApplicationReducer.walletAddress

      let boostQuantity

      try {
        boostQuantity = await gremlinContract.methods.addressBoostQuantity(walletAddress).call()
        boostQuantity = +boostQuantity.toString()
      } catch (error) {
        console.log(error)
      }


      dispatch(AccountActionCreator.setBoostQuantity(boostQuantity))
    },
  getGremlinCurrency:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);
      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      let gremlinCurrency
      const bnbCurrency = await getBnbCurrency()
      try {
        gremlinCurrency = await gremlinContract.methods.gremlinTokenPrice().call()
        gremlinCurrency = gremlinCurrency.toString()
        gremlinCurrency = +web3.utils.fromWei(gremlinCurrency, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      dispatch(ApplicationActionCreator.setBnbCurrency(bnbCurrency))
      dispatch(AccountActionCreator.setGremlinCurrency(gremlinCurrency))
    },
  getTokenBalances:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinTokenContract = new web3.eth.Contract(GremlinToken.abi, config().GREMLIN_TOKEN_CONTRACT)

      const h2oTokenContract = new web3.eth.Contract(GremlinToken.abi, config().H2O_TOKEN_CONTRACT)

      let bnbBalance
      let gremlinBalance
      let h2oBalance

      try {
        bnbBalance = await web3.eth.getBalance(walletAddress)
        bnbBalance = bnbBalance.toString()
        bnbBalance = +web3.utils.fromWei(bnbBalance, 'ether')
      } catch (error) {
        console.log(error)
        return
      }
      try {
        gremlinBalance = await gremlinTokenContract.methods.balanceOf(walletAddress).call()
        gremlinBalance = gremlinBalance.toString()
        gremlinBalance = +web3.utils.fromWei(gremlinBalance, 'ether')
      } catch (error) {
        console.log(error)
        return
      }

      try {
        h2oBalance = await h2oTokenContract.methods.balanceOf(walletAddress).call()
        h2oBalance = h2oBalance.toString()
        h2oBalance = +web3.utils.fromWei(h2oBalance, 'ether')
      } catch (error) {
        console.log(error)
        return
      }

      const tokenBalances = {
        bnbBalance, gremlinBalance, h2oBalance
      }
      dispatch(AccountActionCreator.setTokenBalances(tokenBalances))
    },
  getReferalBuyInfo:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)

      const addressBuyRefferalsByLevel = []


      const currentBuyReferral = await gremlinContract.methods.getCoinRewards(walletAddress).call()

      addressBuyRefferalsByLevel.push(...currentBuyReferral.map(el => {
        let fromWeiVal = el.toString()
        fromWeiVal = +web3.utils.fromWei(fromWeiVal, 'ether')
        return fromWeiVal
      }))


      dispatch(AccountActionCreator.setBuyReferralsByLevel(addressBuyRefferalsByLevel))
    },
  getReferalEarnInfo:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)

      const addressEarnRefferalsByLevel = []

      const currentBuyReferral = await gremlinContract.methods.getGremlinRewards(walletAddress).call()


      addressEarnRefferalsByLevel.push(...currentBuyReferral.map(el => {
        let fromWeiVal = el.toString()
        fromWeiVal = +web3.utils.fromWei(fromWeiVal, 'ether')
        return fromWeiVal
      }))



      dispatch(AccountActionCreator.setEarnReferralsByLevel(addressEarnRefferalsByLevel))
    },
  getTokenAvailableNftInfo:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)

      let availableGremlinNftReward = 0
      let availableH2oNftReward = 0

      try {
        availableGremlinNftReward = await gremlinContract.methods.claimableGremlins(walletAddress).call()
        availableGremlinNftReward = availableGremlinNftReward.toString()
        availableGremlinNftReward = +web3.utils.fromWei(availableGremlinNftReward, 'ether')
        console.log(availableGremlinNftReward)
      } catch (error) {
        console.log(error)
      }

      try {
        availableH2oNftReward = await gremlinContract.methods.claimableH2o(walletAddress).call()
        availableH2oNftReward = availableH2oNftReward.toString()
        availableH2oNftReward = +web3.utils.fromWei(availableH2oNftReward, 'ether')
      } catch (error) {
        console.log(error)
      }
      dispatch(AccountActionCreator.setTokenAvailableNftReward({ availableGremlinNftReward, availableH2oNftReward }))

    },
  getTotalEarnedReward:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)

      let totalEarnedNftRewardGremlin = 0
      let totalEarnedNftRewardH2o = 0

      try {
        totalEarnedNftRewardGremlin = await gremlinContract.methods.withdrawnGremlinNft(walletAddress).call()
        totalEarnedNftRewardGremlin = totalEarnedNftRewardGremlin.toString()
        totalEarnedNftRewardGremlin = +web3.utils.fromWei(totalEarnedNftRewardGremlin, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      try {
        totalEarnedNftRewardH2o = await gremlinContract.methods.withdrawnH2oNft(walletAddress).call()
        totalEarnedNftRewardH2o = totalEarnedNftRewardH2o.toString()
        totalEarnedNftRewardH2o = +web3.utils.fromWei(totalEarnedNftRewardH2o, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      dispatch(AccountActionCreator.setTotalEarnedReward({ totalEarnedNftRewardGremlin, totalEarnedNftRewardH2o }))

    },
  getNftInfo:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)

      const nftBalanceByType = []

      for (let i = 0; i < 6; i++) {
        let currentNftLength

        const nftContract = new web3.eth.Contract(GremlinNft.abi, getNftAddress(i))

        try {
          currentNftLength = await nftContract.methods.getAddressNFTs(walletAddress).call();
          currentNftLength = currentNftLength.length
        } catch (error) {
          console.log(error.message)
          return
        }
        nftBalanceByType.push(currentNftLength)
      }


      dispatch(AccountActionCreator.setNftInfo({ nftBalanceByType }))

    },
  getReferralBuyInfo:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)

      let referralBuyReward
      let withdrawnBuyReward

      try {
        referralBuyReward = await gremlinContract.methods.referralBuyAmount(walletAddress).call()
        referralBuyReward = referralBuyReward.toString()
        referralBuyReward = +web3.utils.fromWei(referralBuyReward, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      try {
        withdrawnBuyReward = await gremlinContract.methods.referralWithdrawnAmount(walletAddress).call()
        withdrawnBuyReward = withdrawnBuyReward.toString()
        withdrawnBuyReward = +web3.utils.fromWei(withdrawnBuyReward, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      dispatch(AccountActionCreator.setReferralBuyInfo({ referralBuyReward, withdrawnBuyReward }))
    },
  getReferralProfitInfo:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      let referralProfitGremlinReward
      let referralProfitH2oReward
      let withdrawnProfitGremlinReward
      let withdrawnProfitH2oReward

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)

      try {
        referralProfitGremlinReward = await gremlinContract.methods.referralGremlinProfitAmount(walletAddress).call()
        referralProfitGremlinReward = referralProfitGremlinReward.toString()
        referralProfitGremlinReward = +web3.utils.fromWei(referralProfitGremlinReward, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      try {
        referralProfitH2oReward = await gremlinContract.methods.referralH2oProfitAmount(walletAddress).call()
        referralProfitH2oReward = referralProfitH2oReward.toString()
        referralProfitH2oReward = +web3.utils.fromWei(referralProfitH2oReward, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      try {
        withdrawnProfitGremlinReward = await gremlinContract.methods.withdrawnGremlinProfitAmount(walletAddress).call()
        withdrawnProfitGremlinReward = withdrawnProfitGremlinReward.toString()
        withdrawnProfitGremlinReward = +web3.utils.fromWei(withdrawnProfitGremlinReward, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      try {
        withdrawnProfitH2oReward = await gremlinContract.methods.withdrawnH2oProfitAmount(walletAddress).call()
        withdrawnProfitH2oReward = withdrawnProfitH2oReward.toString()
        withdrawnProfitH2oReward = +web3.utils.fromWei(withdrawnProfitH2oReward, 'ether')
      } catch (error) {
        console.log(error.message)
        return
      }

      const referralProfitInfo = {
        referralProfitGremlinReward,
        referralProfitH2oReward,
        withdrawnProfitGremlinReward,
        withdrawnProfitH2oReward,
      }

      dispatch(AccountActionCreator.setReferralProfitInfo(referralProfitInfo))
    },
  getUpliner:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)
      let upliner

      try {
        upliner = await gremlinContract.methods.upliner(walletAddress).call();
      } catch (error) {
        console.log(error.message)
        return
      }

      if (upliner === '0x0000000000000000000000000000000000000000') {
        dispatch(ApplicationActionCreator.setIsNeedToSetUpliner(true))
        return
      } else {
        dispatch(ApplicationActionCreator.setIsNeedToSetUpliner(false))
        dispatch(AccountActionCreator.setUpliner(upliner))
      }
    },
  setUplinerContract:
    (upliner) => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT)

      const setUplinerData = gremlinContract.methods.setUpliner(upliner).encodeABI()

      let setUplinerTx



      let gasPrice = await web3.eth.getGasPrice()
      gasPrice = gasPrice.toString()

      let gasLimit = await gremlinContract.methods.setUpliner(upliner).estimateGas({ from: walletAddress });
      gasLimit = gasLimit.toString()


      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: false,
        type: 'setUpliner',
        content: <p>Setting upliner...</p>
      }))

      try {
        setUplinerTx = await web3.eth.sendTransaction({
          from: walletAddress,
          to: config().GREMLIN_CONTRACT,
          data: setUplinerData,
          gas: gasLimit
        })
      } catch (error) {
        dispatch(ApplicationActionCreator.setIsNeedToSetUpliner(false))
        dispatch(ApplicationActionCreator.setIsDisconnect(true))
        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: true,
          isError: true,
          isSuccess: false,
          type: 'setUpliner',
          content: defineError(error)
        }))
        console.log(error)
        return
      }

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: true,
        type: 'setUpliner',
        content: <p>You are successfully set your upliner</p>
      }))

      setTimeout(() => {
        dispatch(ApplicationActionCreator.setIsNeedToSetUpliner(false))
      }, 5000);

    }
}


export const config = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return {
      GREMLIN_CONTRACT: "0xF31431382E4AE4AD989fB35798905D451E823E59",
      GREMLIN_TOKEN_CONTRACT: "0x1036b335c141A9c67DC77f4a145b9F93201e2060",
      H2O_TOKEN_CONTRACT: "0xdd23fc08CFfbf4414439DE6375c21c81FbBc3649",
      NFT_CONTRACT: "0xc8881efA59d0Ebdae9858918406D82c0373801a1",
      WEB3_BSC_URL: 'https://bsc-dataseed.binance.org/',
      BASE_URL: 'https://gremlinsgem.com/?ref=',
      PROJECT_ID: '5b88e380cb7f9736a57c4175e26f1c55',
      CHAIN_ID: 56,
      BSC_SCAN_URL: 'https://bscscan.com/tx/'
    }
  } else {
    return {
      GREMLIN_CONTRACT: "0xC3b3e1c9231910137E569d4E3b85347BF3Cf575A",
      GREMLIN_TOKEN_CONTRACT: "0x3346a187e06E475E730Fa639713817E04AE21dCe",
      H2O_TOKEN_CONTRACT: "0x86873cD493057317012433C16c95b4356CAE54f0",
      NFT_CONTRACT1: "0x23a98ca6715670AF967d2Da31e557C1ae20E245E",
      NFT_CONTRACT2: "0x41497C98f850B18f4c562Cc975081826B2A28905",
      NFT_CONTRACT3: "0x2c09c4eC58ecf35d9280431EFbb69fa8A55E12fF",
      NFT_CONTRACT4: "0x01b76b888B89E92a913dEc0b00dB891eE9ae3333",
      NFT_CONTRACT5: "0x393F9A4448b1fa3165e677042B82d0FA0728A217",
      NFT_CONTRACT6: "0x1f67E122df32bB31CCc1764019e08cE918E98857",
      WEB3_BSC_URL: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      BASE_URL: 'https://testnet.gremlinsgem.com/?ref=',
      PROJECT_ID: '5b88e380cb7f9736a57c4175e26f1c55',
      HEARTBEAT_RATE: 10,
      CHAIN_ID: 97,
      BSC_SCAN_URL: 'https://testnet.bscscan.com/tx/',
      BSC_SCAN_ADDRESS: 'https://testnet.bscscan.com/address/',
      DAY: 300
    }
  }
}

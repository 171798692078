import Web3 from "web3";
import { ApplicationTypes } from "./types";
import GremlinContract from 'contracts/gremlinContract'
import GremlinNft from 'contracts/gremlinNft.json'
import GremlinToken from 'contracts/gremlinToken.json'
import { config } from "config";
import { initWeb3 } from "utils/initWeb3";
import { getUpliner } from "utils/getUpliner";
import { getNftAddress } from "utils/getNftAddress";
import { toBigInt } from "web3-utils";
import { defineError } from "utils/defineError";

export const ApplicationActionCreator = {
  setWalletAddress: (walletAddress) => ({
    type: ApplicationTypes().SET_WALLET_ADDRESS,
    payload: walletAddress
  }),
  setWeb3: (web3) => ({
    type: ApplicationTypes().SET_WEB3_INSTANCE,
    payload: web3
  }),
  setNeedUpdate: (isNeedUpdate) => ({
    type: ApplicationTypes().SET_NEED_UPDATE,
    payload: isNeedUpdate
  }),
  setDefaultReferrer: (defaultReferrer) => ({
    type: ApplicationTypes().SET_DEFAULT_REFERRER,
    payload: defaultReferrer
  }),
  setWalletRPC: (walletRPC) => ({
    type: ApplicationTypes().SET_WALLET_RPC,
    payload: walletRPC
  }),
  setNotCorrectChain: (notCorrectChain) => ({
    type: ApplicationTypes().SET_NOT_CORRECT_CHAIN,
    payload: notCorrectChain
  }),
  setSelectedNftType: (selectedNftType) => ({
    type: ApplicationTypes().SET_SELECTED_NFT_TYPE,
    payload: selectedNftType
  }),
  setAvailableGremlinsToBuy: (availableGremlinsToBuy) => ({
    type: ApplicationTypes().SET_AVAILABLE_GREMLINS_TO_BUY,
    payload: availableGremlinsToBuy
  }),
  setBothTokensNftPrice: (bothTokensNftPrice) => ({
    type: ApplicationTypes().SET_BOTH_TOKENS_NFT_PRICE,
    payload: bothTokensNftPrice
  }),
  setIsNeedToSetUpliner: (isNeedToSetUpliner) => ({
    type: ApplicationTypes().SET_IS_NEED_TO_SET_UPLINER,
    payload: isNeedToSetUpliner
  }),
  setGremlinBuyInput: (gremlinBuyInput) => ({
    type: ApplicationTypes().SET_GREMLIN_BUY_INPUT,
    payload: gremlinBuyInput
  }),
  setBnbCurrency: bnbCurrency => ({
    type: ApplicationTypes().SET_BNB_CURRENCY,
    payload: bnbCurrency
  }),
  setDailyNftReward: dailyNftReward => ({
    type: ApplicationTypes().SET_DAILY_NFT_REWARD,
    payload: dailyNftReward
  }),
  setTransactionStatus: transactionStatus => ({
    type: ApplicationTypes().SET_TRANSACTION_STATUS,
    payload: transactionStatus
  }),
  setIsDisconnect: isDisconnect => ({
    type: ApplicationTypes().SET_IS_DISCONNECT,
    payload: isDisconnect
  }),
  setBoostPrice: boostPrice => ({
    type: ApplicationTypes().SET_BOOST_PRICE,
    payload: boostPrice
  }),
  getBoostPrice:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      let boostPrice

      try {
        boostPrice = await gremlinContract.methods.boostPrice().call()
        boostPrice = boostPrice.toString()
        boostPrice = web3.utils.fromWei(boostPrice, 'ether')
      } catch (error) {
        console.log(error)
        return
      }

      dispatch(ApplicationActionCreator.setBoostPrice(boostPrice))

    },
  getDailyNftReward:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      const dailyNftReward = []

      for (let i = 0; i < 6; i++) {
        let currentNftReward
        try {
          currentNftReward = await gremlinContract.methods.nftCollectionGremlinReward(getNftAddress(i)).call()
          currentNftReward = currentNftReward.toString()
          currentNftReward = +web3.utils.fromWei(currentNftReward, 'ether')
        } catch (error) {
          console.log(error)
        }

        dailyNftReward.push(currentNftReward)

      }

      dispatch(ApplicationActionCreator.setDailyNftReward(dailyNftReward))

    },
  getAvailableGremlinsToBuy:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);

      const gremlinTokenContract = new web3.eth.Contract(GremlinToken.abi, config().GREMLIN_TOKEN_CONTRACT)

      let availableGremlinsToBuy

      try {
        availableGremlinsToBuy = await gremlinTokenContract.methods.balanceOf(config().GREMLIN_CONTRACT).call()
        availableGremlinsToBuy = availableGremlinsToBuy.toString()
        availableGremlinsToBuy = +web3.utils.fromWei(availableGremlinsToBuy, 'ether')
      } catch (error) {
        console.log(error)
        return
      }

      dispatch(ApplicationActionCreator.setAvailableGremlinsToBuy(availableGremlinsToBuy))

    },
  getBothTokensNftPrice:
    () => async (dispatch, store) => {

      const web3 = new Web3(config().WEB3_BSC_URL);

      let bothTokensNftPrice = []

      for (let i = 0; i < 6; i++) {

        const nftContract = new web3.eth.Contract(GremlinNft.abi, getNftAddress(i));


        try {
          let currentTokenPrice = await nftContract.methods.tokenPrice().call()
          currentTokenPrice = currentTokenPrice.toString()
          currentTokenPrice = +web3.utils.fromWei(currentTokenPrice, 'ether')
          bothTokensNftPrice.push(currentTokenPrice)
        } catch (error) {
          console.log(error)
          return
        }

      }
      dispatch(ApplicationActionCreator.setBothTokensNftPrice(bothTokensNftPrice))
    },
  connectConnectWallet:
    () => async (dispatch, store) => {

      const walletRPC = store().ApplicationReducer.walletRPC

      // Create a new Web3 instance using the MetaMask provider
      const web3 = await initWeb3(walletRPC)

      async function getConnectedChainId() {
        try {
          // Request the current chain ID from MetaMask
          const chainId = await web3.eth.getChainId();

          const newChainId = Number(chainId)

          return newChainId;
        } catch (error) {
          console.error('Error retrieving chain ID:', error);
          return null;
        }
      }

      const chainId = await getConnectedChainId()
      const currentAddress = walletRPC.account.address
      console.log('Wallet connected:', currentAddress)
      dispatch(ApplicationActionCreator.setWalletAddress(currentAddress))
    },
  getDefaultReferrer:
    () => async (dispatch, store) => {
      const web3 = new Web3(config().WEB3_BSC_URL);

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      let defaultReferrer

      try {
        defaultReferrer = await gremlinContract.methods.defaultReferrer().call()
      } catch (error) {
        console.log(error)
        return
      }

      dispatch(ApplicationActionCreator.setDefaultReferrer(defaultReferrer))
    },
  claimReferralBuy:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      let withdrawTxn

      const withdrawData = gremlinContract.methods.withdrawCoinRewards().encodeABI()

      let gasLimit = await gremlinContract.methods.withdrawCoinRewards().estimateGas({ from: walletAddress });
      gasLimit = gasLimit.toString()

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: false,
        type: 'claimBuy',
        content: <p>Claiming referral buy reward...</p>
      }))

      try {
        withdrawTxn = await web3.eth.sendTransaction({
          from: walletAddress,
          data: withdrawData,
          to: config().GREMLIN_CONTRACT,
          gas: gasLimit * 2
        })
      } catch (error) {

        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: true,
          isError: true,
          isSuccess: false,
          type: 'claimBuy',
          content: defineError(error)
        }))
        console.log(error.message)
        return
      }

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: true,
        type: 'claimBuy',
        content: <p>Successfully claimed referral buy reward</p>
      }))
    },
  claimReferralEarn:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const walletAddress = store().ApplicationReducer.walletAddress
      const web3 = await initWeb3(walletRPC)

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      let withdrawTxn

      const withdrawData = gremlinContract.methods.withdrawGremlinRewards().encodeABI()

      let gasLimit = await gremlinContract.methods.withdrawGremlinRewards().estimateGas({ from: walletAddress });
      gasLimit = gasLimit.toString()

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: false,
        type: 'claimEarn',
        content: <p>Claiming referral earn reward...</p>
      }))

      try {
        withdrawTxn = await web3.eth.sendTransaction({
          from: walletAddress,
          data: withdrawData,
          to: config().GREMLIN_CONTRACT,
          gas: gasLimit * 2
        })
      } catch (error) {
        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: true,
          isError: true,
          isSuccess: false,
          type: 'claimEarn',
          content: defineError(error)
        }))
        console.log(error.message)
        return
      }

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: true,
        type: 'claimEarn',
        content: <p>Successfully claimed referral earn reward...</p>
      }))
    },
  createNft:
    (nftAmount) => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const web3 = await initWeb3(walletRPC)
      const walletAddress = store().ApplicationReducer.walletAddress
      const selectedNftType = store().ApplicationReducer.selectedNftType

      const currentReferral = getUpliner(store())

      const currentNftAddress = getNftAddress(selectedNftType - 1)

      const nftContract = new web3.eth.Contract(GremlinNft.abi, currentNftAddress);

      const mintNftData = nftContract.methods.buyNFT(nftAmount).encodeABI()

      let mintNftTx

      let gasLimit = await nftContract.methods.buyNFT(nftAmount).estimateGas({ from: walletAddress });
      gasLimit = gasLimit.toString()

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: false,
        type: 'mint',
        content: <p>Minting NFT...</p>
      }))

      console.log(selectedNftType)
      try {
        mintNftTx = await web3.eth.sendTransaction({
          from: walletAddress,
          data: mintNftData,
          to: currentNftAddress,
          gas: gasLimit * 2
        })
      } catch (error) {
        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: true,
          isError: true,
          isSuccess: false,
          type: 'mint',
          content: defineError(error)
        }))
        console.log(error)
        return
      }

      /*const mintedNftIds = mintNftTx.logs.map(log => {

        const currentNftId = Number(toBigInt(log.topics[3]))

        return currentNftId

      })*/
      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: true,
        type: 'mint',
        content: <p>Successfully minted NFT...</p>
      }))


    },
  claimH2O:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const web3 = await initWeb3(walletRPC)
      const walletAddress = store().ApplicationReducer.walletAddress

      const currentReferral = getUpliner(store())

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      const claimH2oData = gremlinContract.methods.getH2o().encodeABI()

      let claimH2OTx

      let gasLimit = await gremlinContract.methods.getH2o().estimateGas({ from: walletAddress });
      gasLimit = gasLimit.toString()

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: false,
        type: 'claimH2o',
        content: <p>Claiming H2O...</p>
      }))

      try {
        claimH2OTx = await web3.eth.sendTransaction({
          from: walletAddress,
          to: config().GREMLIN_CONTRACT,
          data: claimH2oData,
          gas: gasLimit
        })
      } catch (error) {

        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: true,
          isError: true,
          isSuccess: false,
          type: 'claimH2o',
          content: defineError(error)
        }))
        console.log(error)
        return
      }

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: true,
        type: 'claimH2o',
        content: <p>Successfully Claimed H2O</p>
      }))

    },
  getBoost:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const web3 = await initWeb3(walletRPC)
      const walletAddress = store().ApplicationReducer.walletAddress

      const currentReferral = getUpliner(store())

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      let getBoostTx

      const getBoostData = gremlinContract.methods.buyBoost().encodeABI()

      const boostPrice = 10 ** 15

      let gasLimit = await gremlinContract.methods.buyBoost().estimateGas({ from: walletAddress, value: boostPrice });
      gasLimit = gasLimit.toString()

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: false,
        type: 'boost',
        content: <p>Boosting...</p>
      }))

      try {
        getBoostTx = await web3.eth.sendTransaction({
          from: walletAddress,
          to: config().GREMLIN_CONTRACT,
          data: getBoostData,
          value: boostPrice,
          gas: gasLimit
        })
      } catch (error) {
        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: true,
          isError: true,
          isSuccess: false,
          type: 'boost',
          content: defineError(error)
        }))

        console.log(error.code)
        return
      }
      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: true,
        type: 'boost',
        content: <p>Successfully boosted...</p>
      }))
    },
  buyGremlins:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const web3 = await initWeb3(walletRPC)
      const walletAddress = store().ApplicationReducer.walletAddress
      const gremlinBuyInput = store().ApplicationReducer.gremlinBuyInput


      const gremlinAmount = web3.utils.toWei(+gremlinBuyInput, 'ether')
      console.log(gremlinAmount)
      const currentReferral = getUpliner(store())

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);
      console.log(gremlinAmount)
      const buyGremlinsData = gremlinContract.methods.buyGremlins().encodeABI()

      let buyGremlinsTx

      let gasPrice = await web3.eth.getGasPrice()
      gasPrice = gasPrice.toString()

      let gasLimit = await gremlinContract.methods.buyGremlins().estimateGas({ value: gremlinAmount, from: walletAddress });
      gasLimit = gasLimit.toString()

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: false,
        type: 'buy',
        content: <p>Buying GREMLIN...</p>
      }))

      try {
        buyGremlinsTx = await web3.eth.sendTransaction({
          from: walletAddress,
          to: config().GREMLIN_CONTRACT,
          data: buyGremlinsData,
          value: gremlinAmount,
          gas: gasLimit
        })
      } catch (error) {
        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: true,
          isError: true,
          isSuccess: false,
          type: 'buy',
          content: defineError(error)
        }))
        console.log(error)
        return
      }

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: true,
        type: 'buy',
        content: <p>You are successfully bought GREMLIN</p>
      }))
    },
  claimNftRewards:
    () => async (dispatch, store) => {
      const walletRPC = store().ApplicationReducer.walletRPC
      const web3 = await initWeb3(walletRPC)
      const walletAddress = store().ApplicationReducer.walletAddress

      const gremlinContract = new web3.eth.Contract(GremlinContract.abi, config().GREMLIN_CONTRACT);

      const claimNftData = gremlinContract.methods.claimNfts().encodeABI()

      let claimNftTx

      let gasLimit = await gremlinContract.methods.claimNfts().estimateGas({ from: walletAddress });
      gasLimit = gasLimit.toString()

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: false,
        type: 'claimNft',
        content: <p>Claiming NFT reward...</p>
      }))

      try {
        claimNftTx = await web3.eth.sendTransaction({
          from: walletAddress,
          to: config().GREMLIN_CONTRACT,
          data: claimNftData,
          gas: gasLimit
        })
      } catch (error) {
        dispatch(ApplicationActionCreator.setTransactionStatus({
          ongoing: true,
          isError: true,
          isSuccess: false,
          type: 'claimNft',
          content: defineError(error)
        }))
        console.log(error)
        return
      }

      dispatch(ApplicationActionCreator.setTransactionStatus({
        ongoing: true,
        isError: false,
        isSuccess: true,
        type: 'claimNft',
        content: <p>You are successfully claimed NFT reward</p>
      }))

    },
}

export const ApplicationTypes = () => {
  return {
    SET_WEB3_INSTANCE: "SET_WEB3_INSTANCE",
    SET_WALLET_ADDRESS: "SET_WALLET_ADDRESS",
    SET_NEED_UPDATE: "SET_NEED_UPDATE",
    SET_DEFAULT_REFERRER: "SET_DEFAULT_REFERRER",
    SET_WALLET_RPC: "SET_WALLET_RPC",
    SET_NOT_CORRECT_CHAIN: "SET_NOT_CORRECT_CHAIN",
    SET_SELECTED_NFT_TYPE: "SET_SELECTED_NFT_TYPE",
    SET_AVAILABLE_GREMLINS_TO_BUY: 'SET_AVAILABLE_GREMLINS_TO_BUY',
    SET_BOTH_TOKENS_NFT_PRICE: 'SET_BOTH_TOKENS_NFT_PRICE',
    SET_IS_NEED_TO_SET_UPLINER: 'SET_IS_NEED_TO_SET_UPLINER',
    SET_GREMLIN_BUY_INPUT: 'SET_GREMLIN_BUY_INPUT',
    SET_BNB_CURRENCY: 'SET_BNB_CURRENCY',
    SET_DAILY_NFT_REWARD: 'SET_DAILY_NFT_REWARD',
    SET_TRANSACTION_STATUS: 'SET_TRANSACTION_STATUS',
    SET_IS_DISCONNECT: 'SET_IS_DISCONNECT',
    SET_BOOST_PRICE: 'SET_BOOST_PRICE'
  }
}


const { config } = require("config")

export const getNftAddress = (type) => {

  let currentNftContract

  switch (type) {
    case 0:
      currentNftContract = config().NFT_CONTRACT1
      break;
    case 1:
      currentNftContract = config().NFT_CONTRACT2
      break;
    case 2:
      currentNftContract = config().NFT_CONTRACT3
      break;
    case 3:
      currentNftContract = config().NFT_CONTRACT4
      break;
    case 4:
      currentNftContract = config().NFT_CONTRACT5
      break;
    case 5:
      currentNftContract = config().NFT_CONTRACT6
      break;
    default:
      break;
  }

  return currentNftContract

}

import { useWeb3Modal } from '@web3modal/react'
import Logo from 'assets/logo.png'
import { Button } from 'components/button/button'
import { ConnectWallet } from 'components/connectWallet/connectWallet'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Menu } from 'assets/menu.svg'
import { ReactComponent as Close } from 'assets/close.svg'
import { ReactComponent as Exit } from 'assets/exit.svg'


export const Header = (props) => {

  const [currentTab, setCurrentTab] = useState('home')
  const { walletAddress } = useSelector(state => state.ApplicationReducer)
  const { open } = useWeb3Modal();
  const location = useLocation()

  const signInButtonClickHandler = useCallback(() => {
    open()
  }, [window.wallet, walletAddress])

  const isMobile = window.innerWidth < 640

  const buttonTextContent = () => {
    if (walletAddress) {
      return walletAddress.slice(0, 4) + '...' + walletAddress.slice(-4);
    } else {
      return 'Connect Wallet'
    }
  }
  return (
    <header className='fixed z-20 top-0 left-0 overflow-hidden bg-bgPurple w-full flex items-center py-5'>
      <div className='flex wrapper items-center justify-between sm:mx-auto'>
        <Link to='/' className='flex items-center gap-x-3'>
          <img src={Logo} className='w-9 h-8 sm:w-12 sm:h-10 mmx:w-16 mmx:h-16 self-start ' />
          <p className='whitespace-nowrap font-jetBold text-xl sm:text-4xl mmx:text-5xl font-bold text-white uppercase'>gremlins gem</p>
        </Link>
        {
          !isMobile && <div className='flex text-base mr-20 font-jetRegular tracking-widest justify-evenly text-white w-full'>
            <Link to='/' className={`cursor-pointer ${location.pathname === '/' && 'text-lightPurple'} hover:text-lightPurple transition`} >Home</Link>
            <Link to='/nft' className={`cursor-pointer ${location.pathname === '/nft' && 'text-lightPurple'} hover:text-lightPurple transition`} >NFT</Link>
            <Link to='/statistic' className={`cursor-pointer ${location.pathname === '/statistic' && 'text-lightPurple'} hover:text-lightPurple transition`} >Statistics</Link>
          </div>
        }
        {
          !isMobile
            ? <div className='flex items-center gap-x-5'>
              <ConnectWallet walletAddress={walletAddress} text={buttonTextContent} onClick={signInButtonClickHandler} />
            </div>
            : props.openedMenu
              ? <div onClick={() => props.setIsOpenedMenu(false)}>
                <Close />
              </div>
              : <div onClick={() => props.setIsOpenedMenu(true)}>
                <Menu />
              </div>
        }
      </div>
    </header>
  )
}

export const HeaderMenu = (props) => {
  const location = useLocation()
  const { walletAddress } = useSelector(state => state.ApplicationReducer)
  const { open } = useWeb3Modal();

  const buttonTextContent = () => {
    if (walletAddress) {
      return walletAddress.slice(0, 6) + '...' + walletAddress.slice(-5);
    } else {
      return 'Connect Wallet'
    }
  }

  const signInButtonClickHandler = useCallback(() => {
    open()
  }, [window.wallet, walletAddress])

  return (
    <div className='fixed z-10 w-screen h-screen bg-bgPurple left-0 top-0 flex flex-col items-center'>
      <div className='flex flex-col font-jetMedium mt-36 mb-20 gap-y-24 items-center text-4xl font-medium justify-evenly text-white w-full'>
        <Link onClick={() => props.setIsOpenedMenu(false)} to='/' className={`cursor-pointer ${location.pathname === '/' && 'text-lightPurple'} hover:text-lightPurple transition`} >Home</Link>
        <Link onClick={() => props.setIsOpenedMenu(false)} to='/nft' className={`cursor-pointer ${location.pathname === '/nft' && 'text-lightPurple'} hover:text-lightPurple transition`} >NFT</Link>
        <Link onClick={() => props.setIsOpenedMenu(false)} to='/statistic' className={`cursor-pointer ${location.pathname === '/statistic' && 'text-lightPurple'} hover:text-lightPurple transition`} >Statistics</Link>
      </div>
      <div className='flex items-center gap-x-5'>
        <ConnectWallet walletAddress={walletAddress} text={buttonTextContent} onClick={signInButtonClickHandler} />
      </div>
    </div>
  )
}

import Carousel from 'assets/airdrop/carousel.png'
import CarouselMobile from 'assets/airdrop/carouselMobile.png'
import { ReactComponent as Telegram } from 'assets/telegram.svg'
import { ReactComponent as Twitter } from 'assets/twitter.svg'

export const AirdropCarousel = () => {

  const isMobile = window.innerWidth < 640

  console.log(isMobile)

  return (
    <div className='pb-5 sm:pb-10'>
      <div className='relative bg-darkPurple flex flex-col justify-between items-center rounded-3xl'>
        <h3 className='text-2xl sm:text-5xl w-2/3 airdrop__carousel text-center font-jetBold leading-55 tracking-widest text-white mt-8 sm:mt-16 mb-5 z-10'>Gremlins Gem AirDrop</h3>
        <p className='mb-8 text-base sm:text-xl font-interMedium text-description z-10'>Get more tokens right now!</p>
        <div className='flex items-center gap-x-16 mb-8'>
          <a href='https://twitter.com/gremlinsgem' target='_blank' className='flex items-center font-interMedium text-white gap-x-3 z-10 cursor-pointer '> <Twitter className='w-8 h-8' /> </a>
          <a href='https://t.me/gremlins_gem_channel' target='_blank' className='flex items-center font-interMedium text-white gap-x-3 z-10 cursor-pointer '> <Telegram className='w-8 h-8' /> </a>
        </div>
        <img className='absolute  h-full left-1/2 top-0 transform -translate-x-1/2' src={isMobile ? CarouselMobile : Carousel} />
      </div>
    </div>

  )
}

import { AccountTypes } from "./types"

const initialState = {
  upliner: null,
  referralsByLevel: [0, 0, 0, 0, 0, 0, 0],
  gremlinCurrency: 0,
  boostQuantity: 0,
  h2oCooldown: 0,
  tokenBalances: {
    bnbBalance: 0,
    h2oBalance: 0,
    gremlinBalance: 0,
  },
  nftIds: [],
  nftInfo: {
    nftBalanceByType: [0, 0, 0, 0, 0, 0],
    totalEarnedNftRewardGremlin: 0,
    totalEarnedNftRewardH2o: 0,
    availableGremlinNftReward: 0,
    availableH2oNftReward: 0
  },
  referralBuyInfo: {
    addressBuyRefferalsByLevel: [0, 0, 0, 0, 0],
    addressEarnReferralsByLevel: [0, 0, 0, 0, 0, 0, 0],
  },
  referralProfitInfo: {
    availableBuyReward: 0,
    availableEarnReward: 0,
  },
}

export default function AccountReducer(
  state = initialState, action
) {
  switch (action.type) {
    case AccountTypes().SET_UPLINER:
      return { ...state, upliner: action.payload }
    case AccountTypes().SET_TOKEN_BALANCES:
      return { ...state, tokenBalances: action.payload }
    case AccountTypes().SET_REFERRALS_BY_LEVEL:
      return { ...state, referralsByLevel: action.payload }
    case AccountTypes().SET_NFT_INFO:
      return { ...state, nftInfo: { ...state.nftInfo, ...action.payload } }
    case AccountTypes().SET_REFERRAL_BUY_INFO:
      return { ...state, referralBuyInfo: action.payload }
    case AccountTypes().SET_REFERRAL_PROFIT_INFO:
      return { ...state, referralProfitInfo: action.payload }
    case AccountTypes().RESET_USER_INFO:
      return {
        ...state,
        upliner: null,
        referralsByLevel: [0, 0, 0, 0, 0],
        gremlinCurrency: 0,
        boostQuantity: 0,
        tokenBalances: {
          bnbBalance: 0,
          h2oBalance: 0,
          gremlinBalance: 0,
        },
        nftIds: [[], [], [], [], [], []],
        nftInfo: {
          nftBalanceByType: [0, 0, 0, 0, 0, 0],
          totalEarnedNftRewardGremlin: 0,
          totalEarnedNftRewardH2o: 0,
          availableGremlinNftReward: 0,
          availableH2oNftReward: 0
        },
        referralBuyInfo: {
          addressBuyRefferalsByLevel: [0, 0, 0, 0, 0],
          addressEarnReferralsByLevel: [0, 0, 0, 0, 0, 0, 0],
        },
        referralProfitInfo: {
          referralProfitGremlinReward: 0,
          referralProfitH2oReward: 0,
          withdrawnProfitGremlinReward: 0,
          withdrawnProfitH2oReward: 0,
        },
      }
    case AccountTypes().SET_GREMLIN_CURRENCY:
      return { ...state, gremlinCurrency: action.payload }
    case AccountTypes().SET_BOOST_QUANTITY:
      return { ...state, boostQuantity: action.payload }
    case AccountTypes().SET_TOKEN_AVAILABLE_NFT_REWARD:
      return { ...state, nftInfo: { ...state.nftInfo, ...action.payload } }
    case AccountTypes().SET_TOTAL_EARNED_REWARD:
      return { ...state, nftInfo: { ...state.nftInfo, ...action.payload } }
    case AccountTypes().SET_BUY_REFERRALS_BY_LEVEL:
      return { ...state, referralBuyInfo: { ...state.referralBuyInfo, addressBuyRefferalsByLevel: action.payload } }
    case AccountTypes().SET_EARN_REFERRALS_BY_LEVEL:
      return { ...state, referralBuyInfo: { ...state.referralBuyInfo, addressEarnReferralsByLevel: action.payload } }
    case AccountTypes().SET_AVAILABLE_REFERRAL_REWARDS:
      return { ...state, referralProfitInfo: action.payload }
    case AccountTypes().SET_H2O_COOLDOWN:
      return { ...state, h2oCooldown: action.payload }
    case AccountTypes().SET_NFT_IDS:
      return { ...state, nftIds: action.payload }
    default:
      return state
  }
}

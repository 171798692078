import { Web3Modal } from "@web3modal/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"
import { AccountActionCreator } from "store/reducers/accountReducer/action-creator"
import { ApplicationActionCreator } from "store/reducers/applicationReducer/action-creator"
import { useAccount, useWalletClient } from "wagmi"
import { disconnect } from '@wagmi/core'
import { AirDrop } from "pages/airdrop/airdrop"

export const ApplicationRoutes = (props) => {
  const { connector: activeConnector, address, isConnecting, isDisconnected } = useAccount()
  const { data, isError } = useWalletClient()
  const { isDisconnect } = useSelector(state => state.ApplicationReducer)
  const dispatch = useDispatch()

  const disconnectWallet = async () => {
    await disconnect()
    dispatch(AccountActionCreator.resetUserInfo())
    dispatch(ApplicationActionCreator.setWalletAddress(null))
  }

  useEffect(() => {
    if (!!data && !isDisconnected) {
      dispatch(ApplicationActionCreator.setWeb3(data))
      dispatch(ApplicationActionCreator.connectConnectWallet())
    } else if (data === undefined && isDisconnected === undefined) {
      disconnectWallet()
    }
  }, [address, data])

  useEffect(() => {
    if (isDisconnected || isDisconnect) {
      dispatch(ApplicationActionCreator.setIsDisconnect(false))
      disconnectWallet()
    }
  }, [isDisconnected, isDisconnect])

  return (
    <>
      <Web3Modal explorerRecommendedWalletIds={[
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'
      ]} projectId={props.projectId} ethereumClient={props.ethereumClient} />
      <div className='content__wrapper tracking-widest'>
        <Routes>
          <Route path='/' element={<AirDrop />} />
        </Routes>
      </div>
    </>
  )
}


export const getBnbCurrency = async () => {
  const bnbCurrency = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT', {
    method: "GET",
  })
    .then(res => res.json())
    .then(data => data.price)

  return bnbCurrency

}
